import restaurant from "./images/sevens1.png";
import biserica from "./images/biserica.png";
import img_card from "./images/surori.jpg";
import imgheadermb from "./images/h13.jpg";
import imgheader from "./images/h13.jpg";
import imgheadermiini from "./images/h14.jpg";
import imgheadermiinimb from "./images/h14.jpg";
import logo from './images/fluture2.png'



const data = {
    introData: [{
        copilul: "Melissa și Andya",
        familia: "fam. Chilimicenco",
        logo: logo,
        tata: "Oleg",
        mama: "Ana-Bianka",
        data: "06 August 2023",
        data_confirmare: "25 iulie 2023",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "biankachilimicenco@gmail.com", 
       tel: "+37368556797",
       phone: "tel:+37368556797",
       viber: "viber://chat?number=%2B37368556797",
       whatsapp: "https://wa.me/+37368556797",
       messenger: "https://www.messenger.com/t/bianka.bianka.9678",
       tel1: "+37376745651",
       phone1: "tel:+37376745651",
       viber1: "viber://chat?number=%2B37376745651",
       whatsapp1: "https://wa.me/+37376745651",
       messenger1: "https://www.messenger.com/t/oleg.biankachilimicenco",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Sf. Botez",
            localul: "Biserica",
            name: "Sfântul Spiridon",
            data: "06 august 2023, duminică, ora 12:30",
            adress: "str. Doina si Ion Aldea Teodorovici 13 ",
            harta: "https://goo.gl/maps/GxNCPcAC3dvMjkwf6",
            iframe: ""
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Sevens Pub",
            data: "06 august 2023, duminică, ora 14:00",
            adress: "str. Paris 47A, Chișinău",
            harta: "https://goo.gl/maps/3ygMk65duMbE3VC2A",
            iframe: ""
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Copiii sunt un dar al lui Dumnezeu, un motiv de a crede în minuni, sunt pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;