import React, { Component } from "react";

export default class Card extends Component {
    render() {
        return (
            
                    <div className="col-md-4 col-lg-4 col-12 m-4">
                        <h5 className="card-title text-center p-2" style={{color: 'white'}}>{this.props.title}</h5>
                        <div className="card p-0 overflow-hidden h-100 shadow">
                            <img src={this.props.img} className="card-img-top" />
                            <div className="card-body text-center">
                            <h4 className="card-title">{this.props.localul}</h4>
                                <h3 style={{ fontFamily: 'Dancing Script',fontSize: '40px'}} className="card-title">"{this.props.name}"</h3>
                                <h5 className="card-title">{this.props.data}</h5>
                                <p className="card-text">{this.props.adress}</p>
                                <a href={this.props.harta} class="btn btn-primary">Vezi Harta</a>
                            </div>
                        </div>
                    </div>
              
        )
    }
}

